export class OverlayAnimator {
    constructor(options = {}) {
        this.overlayParents = document.querySelectorAll('.overlay-parent');
        this.overlayFirstChild = document.querySelector('.overlay-first-child');
        this.threshold = options.threshold || 1;
        this.transitionDuration = options.transitionDuration || '0.5s';
        this.initObserver();
        this.initScrollEvent();
    }

    initObserver() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const overlayChild = entry.target.querySelector('.overlay-child');
                    if (overlayChild) {
                        this.animateElement(overlayChild);
                    }
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: this.threshold
        });

        this.overlayParents.forEach((parent) => observer.observe(parent));
    }

    initScrollEvent() {
        window.addEventListener('scroll', () => {
            if (this.overlayFirstChild && window.scrollY > 100) {
                this.animateElement(this.overlayFirstChild);
            }
        });
    }

    animateElement(element) {
        element.style.transform = 'translateX(0%)';
        element.style.transition = `transform ${this.transitionDuration} ease-in-out`;
    }
}
