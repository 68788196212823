import '../scss/app.scss'; // Importing SCSS file
import { NewsTicker } from './NewsTicker';
import { OverlayAnimator } from './OverlayAnimator';

 

document.addEventListener('DOMContentLoaded', function () {

 


    // Usage
    const ticker = new NewsTicker('.news-container ul', 40, 1000);



    // Initialize the OverlayAnimator class
    new OverlayAnimator({
        threshold: .5,
        transitionDuration: '1.5s'
    });

});