export class NewsTicker {
    constructor(containerSelector, moveInterval = 1000, createInterval = 1000) {
        this.container = document.querySelector(containerSelector);
        this.moveInterval = moveInterval;
        this.createInterval = createInterval;
        this.translateX = 0;

        // Start the ticker functionality
        this.startMoving();
        this.startAddingItems();
    }

    moveLeft() {
        console.log('move left');

        // Update the transform property to move the list left
        this.translateX -= 1; // Adjust this value to control the speed

        console.log(this.translateX);

        this.container.style.transform = `translateX(${this.translateX}px)`;

        // Remove the first element if it goes out of view
    }

    createNewItem() {
        // Create and append a new list item
        const newLi = document.createElement('li');
        newLi.textContent = "Staffing Done Differently";
        this.container.appendChild(newLi);
    }

    startMoving() {
        setInterval(() => this.moveLeft(), this.moveInterval);
    }

    startAddingItems() {
        setInterval(() => this.createNewItem(), this.createInterval);
    }
}
